export const AuthHeader = () => {
	const srcToken = localStorage.getItem('srcToken')
	let user = null
	if (srcToken) {
		user = JSON.parse(srcToken)
		if (user) {
			return { Authorization: user }
		} else {
			return { Authorization: `` }
		}
	}
}
