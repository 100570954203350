import process from 'process'
import axios from 'axios'
import { AuthHeader } from '../service/AuthHeader'
import { useDispatch } from 'react-redux'
import { notification } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import React, { FC, PropsWithChildren } from 'react'

export const instance = axios.create({
	headers: AuthHeader()
})

export const ErrorWrapper: FC<PropsWithChildren> = ({ children }) => {
	const [api, contextHolder] = notification.useNotification()

	const openNotification = (textError: string) => {
		api.open({
			message: `Ошибка с кодом ${textError}`,
			description: 'Произошла ошибка',
			icon: <CloseCircleOutlined style={{ color: 'red' }} />
		})
	}
	instance.interceptors.response.use(
		(config) => config,
		async (error) => {
			openNotification(
				error.response.status == undefined ? 'сервера' : error.response.status
			)
			console.log(error)
		}
	)
	return (
		<>
			{contextHolder}
			{children}
		</>
	)
}
