import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	showModal: false
}

const modalSlice = createSlice({
	name: 'modal',
	initialState,
	reducers: {
		setModalStatus: (state: any, action: any) => {
			state.showModal = action.payload
		}
	}
})

export const { setModalStatus } = modalSlice.actions
export default modalSlice.reducer
