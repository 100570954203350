import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AuthService from '../../service/Auth/Auth.service'

import { IAuthTypes } from './Auth.types'
import { IUser } from '../../types/user'
import * as url from 'url'

const data = localStorage.getItem('ClientTesting')
const user = data ? JSON.parse(data) : null

export const logIn = createAsyncThunk(
	'/login',
	async (users: IUser, thunkAPI) => {
		try {
			const data = await AuthService.Login(users)
			return data
		} catch (err) {}
	}
)
export const loginInfo = createAsyncThunk('/loginInfo', async (thunkAPI) => {
	try {
		const data = await AuthService.loginInfo()
		return data
	} catch (err) {}
})
export const loginAuthHeader = createAsyncThunk(
	'/loginAuthHeader',
	async (url: string, thunkAPI) => {
		try {
			const data = await AuthService.loginAuthHeader(url)
			return data
		} catch (err) {}
	}
)

const initialState: IAuthTypes = user
	? { isLoggedInTest: true, user }
	: { isLoggedInTest: false, user: null }

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(logIn.fulfilled, (state, action) => {
			if (action.payload !== undefined) {
				state.isLoggedInTest = true
				// state.user = action.payload
			} else {
				state.isLoggedInTest = false
				state.user = null
			}
		})
		builder.addCase(logIn.rejected, (state, action) => {
			state.isLoggedInTest = false
			state.user = null
		})
	}
})

const { reducer } = authSlice
export default reducer
