import React, { useEffect, useState } from 'react'
import style from './Main.module.scss'
import Vector from '../../utils/icon/BGSVG.svg'

import { Outlet, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getTest } from '../../redux/test/testSlice'
import Loading from '../../component/loading/Loading'
import { AppDispatch } from '../../redux/store'
import Modal from '../../component/Modal/Modal'

const Main = () => {
	const [loading, setLoading] = useState(true)
	const dispatch = useDispatch<AppDispatch>()
	const navigate = useNavigate()

	const src = useSelector((state: any) => state.test)
	const quest = useSelector((state: any) => state.test.question)
	// const { TestInfoUser } = useSelector((state: any) => state.test.test)
	const parse = localStorage.getItem('TestInfoUser')
	const TestInfoUser = JSON.parse(parse!)
	useEffect(() => {
		navigate('/test/start')
	}, [])

	return (
		<div
			className={style.root}
			style={{
				backgroundColor: '#E7EDF6'
			}}
		>
			<Outlet />
		</div>
	)
}

export default Main
