import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AuthService from '../../service/Auth/Auth.service'
import { ITestInfo } from './TestInfo.types'
import TestService from '../../service/Test/Test.service'
import { IUser } from '../../types/user'
import { IAddAnswer } from '../../types/IAddAnswer'

const TestInfo = localStorage.getItem('TestInfo')
const QuestionInfo = localStorage.getItem('QuestionInfo')
const AnswerInfo = localStorage.getItem('AnswerInfo')

const test = TestInfo ? JSON.parse(TestInfo) : null
const question = QuestionInfo ? JSON.parse(QuestionInfo) : null
const answer = AnswerInfo ? JSON.parse(AnswerInfo) : null

export const getTest = createAsyncThunk('/testInfo', async (thunkAPI) => {
	try {
		const data = await TestService.getInfoTest()
		return data
	} catch (error) {
		localStorage.setItem('ClientTesting', '')
	}
})

export const getQuestion = createAsyncThunk(
	'/testQuestion',
	async (id: number, thunkAPI) => {
		try {
			const data = await TestService.getQuestion(id)
			return { testQuestion: data }
		} catch (error) {
			console.log(error)
		}
	}
)

export const getQuestionTest = createAsyncThunk(
	'/testQuestion',
	async (thunkAPI) => {
		try {
			const data = await TestService.getQuestionTest()
			return { testQuestion: data }
		} catch (error) {
			console.log(error)
		}
	}
)

export const addAnswer = createAsyncThunk(
	'/testAnswer',
	async (answer: IAddAnswer, thunkAPI) => {
		try {
			const data = await TestService.addAnswer(answer)
			return data
		} catch (error) {
			console.log(error)
		}
	}
)

export const startTest = createAsyncThunk(
	'/startTestAnswer',
	async (thunkAPI) => {
		try {
			const data = await TestService.startTest()
			return data
		} catch (error) {
			console.log(error)
		}
	}
)

export const getQuestionList = createAsyncThunk(
	'/getQuestionList',
	async (thunkAPI) => {
		try {
			const data = await TestService.getQuestionList()
			return data
		} catch (error) {
			console.log(error)
		}
	}
)

export const endTest = createAsyncThunk('/endTestAnswer', async (thunkAPI) => {
	try {
		const data = await TestService.endTest()
		return data
	} catch (error) {
		console.log(error)
	}
})

export const nextQuestionTest = createAsyncThunk(
	'/nextQuestionTest',
	async (thunkAPI) => {
		try {
			const data = await TestService.nextQuestionTest()
			return data
		} catch (error) {
			console.log(error)
		}
	}
)
export const backQuestionTest = createAsyncThunk(
	'/nextQuestionTest',
	async (thunkAPI) => {
		try {
			const data = await TestService.backQuestionTest()
			return data
		} catch (error) {
			console.log(error)
		}
	}
)

export const setQuestionTest = createAsyncThunk(
	'/nextQuestionTest',
	async (id: number, thunkAPI) => {
		try {
			const data = await TestService.setQuestionTest(id)
			return data
		} catch (error) {
			console.log(error)
		}
	}
)

export const checkQuestionTest = createAsyncThunk(
	'/nextQuestionTest',
	async (thunkAPI) => {
		try {
			const data = await TestService.checkQuestionTest()
			return data
		} catch (error) {
			console.log(error)
		}
	}
)

const initialState: ITestInfo = { answer, test, question }

const testSlice = createSlice({
	name: 'test',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getTest.fulfilled, (state, action) => {
			state.test = test
		})
		builder.addCase(getTest.rejected, (state, action) => {
			state.test = null
		})

		builder.addCase(getQuestion.fulfilled, (state, action) => {
			state.question = question
		})
		builder.addCase(getQuestion.rejected, (state, action) => {
			state.question = null
		})

		builder.addCase(addAnswer.fulfilled, (state, action) => {
			state.answer = action.payload
		})
		builder.addCase(addAnswer.rejected, (state, action) => {
			state.answer = null
		})
	}
})

const { reducer } = testSlice
export default reducer
