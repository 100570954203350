import axios from 'axios'
import { AuthHeader } from '../AuthHeader'

import { API_URLT } from '../../utils/Constant/url.constant'
import { IAddAnswer } from '../../types/IAddAnswer'

const getInfoTest = () => {
	return axios
		.get(API_URLT + 'testing/info', { headers: AuthHeader() })
		.then((response) => {
			if (response.data) {
				localStorage.setItem('TestInfoUser', JSON.stringify(response.data))
			}

			return response.data
		})
}

const addAnswer = ({ questionId, answerIndex }: IAddAnswer) => {
	return axios
		.post(
			`${API_URLT}testing/test/answer`,
			{ answerText: [answerIndex], id: questionId },
			{ headers: AuthHeader() }
		)
		.then((response) => {
			if (response.data) {
			}

			return response.data
		})
}

const startTest = () => {
	return axios
		.post(`${API_URLT}testing/start`, {}, { headers: AuthHeader() })
		.then((response) => {
			return response.data
		})
}

const getQuestionList = () => {
	return axios
		.get(`${API_URLT}testing/test/all`, { headers: AuthHeader() })
		.then((response) => {
			if (response.data) {
			}

			return response.data
		})
}

const endTest = () => {
	return axios
		.post(`${API_URLT}testing/test/end`, {}, { headers: AuthHeader() })
		.then((response) => {
			if (response.data) {
			}

			return response.data
		})
}

const getQuestion = (id: number) => {
	return axios
		.get(`${API_URLT}testing/test/${id}`, { headers: AuthHeader() })
		.then((response) => {
			if (response.data) {
				return response.data
			} else {
				return response.status
			}
		})
}

const getQuestionTest = () => {
	return axios
		.get(`${API_URLT}testing/test/one`, { headers: AuthHeader() })
		.then((response) => {
			if (response.data) {
				return response.data
			} else {
				return response.status
			}
		})
}

const nextQuestionTest = () => {
	return axios
		.post(`${API_URLT}testing/test/next`, {}, { headers: AuthHeader() })
		.then((response) => {
			if (response.data) {
				return response.data
			} else {
				return response.status
			}
		})
}

const backQuestionTest = () => {
	return axios
		.post(`${API_URLT}testing/test/back`, {}, { headers: AuthHeader() })
		.then((response) => {
			if (response.data) {
				return response.data
			} else {
				return response.status
			}
		})
}

const setQuestionTest = (id: number) => {
	return axios
		.post(`${API_URLT}testing/test/set/${id}`, {}, { headers: AuthHeader() })
		.then((response) => {
			if (response.data) {
				return response.data
			} else {
				return response.status
			}
		})
}

const checkQuestionTest = () => {
	return axios
		.get(`${API_URLT}testing/test/check`, { headers: AuthHeader() })
		.then((response) => {
			if (response.data) {
				return response.data
			} else {
				return response.status
			}
		})
}

const TestService = {
	getInfoTest,
	getQuestion,
	addAnswer,
	startTest,
	getQuestionList,
	endTest,
	getQuestionTest,
	nextQuestionTest,
	backQuestionTest,
	setQuestionTest,
	checkQuestionTest
}

export default TestService
