import React, { FC, useState } from 'react'
import style from './Root.module.scss'
import { Button, Col, Form, Input, notification, Row } from 'antd'
import { ReactComponent as MainIlustration } from '../../../utils/img/ilustration.svg'
import { ReactComponent as IconClose } from '../../../utils/icon/IconClose.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { loginAuthHeader, loginInfo } from '../../../redux/Auth/AuthSlice'
import { AppDispatch } from '../../../redux/store'
const RootPage: FC = () => {
	const { pathname } = useLocation()
	console.log(pathname)
	const navigate = useNavigate()
	const [page, setPage] = useState(1)
	const [api, contextHolder] = notification.useNotification()
	const dispatch = useDispatch<AppDispatch>()
	const openNotification = (textError: string, type: string) => {
		api.open({
			message: type == 'error' ? `Ошибка на сервере` : 'Уведомление',
			description: `${textError}`,
			icon:
				type == 'error' ? (
					<CloseCircleOutlined style={{ color: 'red' }} />
				) : (
					<CheckCircleOutlined style={{ color: 'rgba(0, 146, 47, 1)' }} />
				)
		})
	}
	const onNavigate = () => {
		window.location.reload()
		navigate('/admin/login')
		window.location.reload()
	}
	const onLink = (id: number) => {
		console.log(page)
		setPage(id)
	}
	const pageVisible = page === 1 ? '' : 'page-link'
	console.log(page)
	const onFinish = ({ src }: any) => {
		const srcParse = src.match(/^(https:.*)login\/(.+)$/)

		if (srcParse === null) {
			openNotification('Ссылка недействительна', 'error')
		} else {
			const userLink = srcParse[2]
			dispatch(loginAuthHeader(userLink))
				.unwrap()
				.then((request: any) => {
					if (request.status == 200) {
						navigate(`/login/${userLink}`)
						openNotification(`${userLink}`, 'noti')
					} else {
						console.log(request)
						openNotification(`Ошибка ${request.response.status}`, 'error')
					}
				})
		}
	}
	return (
		<div className={style.root}>
			{contextHolder}
			<Row className={`${style.main} ${page === 1 ? '' : style.page_link}`}>
				<Row className={style.main_info}>
					<Col span={12}>
						<div className={style.text_wrapper}>
							<div className={style.text}>
								<h2 className='text-prim'>Портал тестирования</h2>
								<h1>Продуктивный сотрудник</h1>
							</div>

							<Row gutter={[16, 16]}>
								<Col span={18}>
									<Button
										onClick={() => onLink(2)}
										className={'btn-primary'}
										type={'primary'}
									>
										Пройти опрос/тестирование
									</Button>
								</Col>
								<Col span={6}>
									<a className='' href={`${pathname}admin/login`}>
										<Button>Войти</Button>
									</a>
								</Col>
							</Row>
						</div>
					</Col>
					<Col span={12}>
						<MainIlustration />
					</Col>
				</Row>
				<Row className={style.main_link}>
					<div className={style.close} onClick={() => setPage(1)}>
						<IconClose />
					</div>
					<div className={style.link_wrapper}>
						<Form
							name='normal_login'
							initialValues={{ remember: false }}
							className={style.FormPage}
							onFinish={onFinish}
						>
							<Row gutter={[24, 0]}>
								<Col span={24} style={{ textAlign: 'center' }}>
									<h2 className='h2-semi-bold'>Вставьте ссылку на тест</h2>
								</Col>
								<Col span={24}>
									<Form.Item
										className={style.FormItem}
										name='src'
										rules={[
											{ required: true, message: 'Вы не указали ссылку!' }
										]}
									>
										<Input
											className={style.Input}
											placeholder='Ссылка на тест'
										/>
									</Form.Item>
								</Col>
								<Col span={24}>
									<Button
										htmlType={'submit'}
										style={{ width: '100%' }}
										className='btn-primary'
										type={'primary'}
									>
										Продолжить
									</Button>
								</Col>
							</Row>
						</Form>
					</div>
				</Row>
			</Row>
		</div>
	)
}

export default RootPage
