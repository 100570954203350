import React from 'react'
import { Button } from 'antd'
import img from '../../../utils/IqIcon/3/39/3.jpg'
interface IButtonProps {
	buttonData: string[]
	onClick: (answerIndex: string) => void
	allData: any
}

const ButtonAnswer = ({ buttonData, onClick, allData }: IButtonProps) => {
	// const img = '../../../utils/IqIcon/3/39/3.jpg'
	const imgPath = '3/39/3.jpg'
	console.log(allData)
	console.log(buttonData)
	//)
	const selectAnswer = allData.peopleAnswer
		? allData.peopleAnswer[0]
		: allData.peopleAnswer

	return (
		<>
			{buttonData
				.filter((x) => x)
				.map((obj: string, i: number) => (
					<React.Fragment key={i}>
						<Button
							key={i}
							onClick={(answer) => onClick(obj)}
							type='default'
							icon={<span className='icon-btn'>{i + 1}.</span>}
							className={selectAnswer == obj ? 'active-btn-test' : ''}
						>
							{obj?.match(/<img>/) ? (
								<img
									src={require(`../../../utils/IqIcon/${obj.replace(
										/<img>/gi,
										''
									)}`)}
									alt='1'
								/>
							) : (
								obj
							)}
						</Button>
					</React.Fragment>
				))}
		</>
	)
}

export default ButtonAnswer
