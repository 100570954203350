import { configureStore } from '@reduxjs/toolkit'
import error from './user/errorSlice'
import auth from './Auth/AuthSlice'
import test from './test/testSlice'
import ModalTest from './Modal/ModalSlice'
export const store = configureStore({
	reducer: {
		error,
		auth,
		test,
		ModalTest
	}
})
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
