import style from './end.module.scss'
import { ReactComponent as PeopleIcon } from '../../../utils/icon/peopleWin2.svg'
import { ReactComponent as Face } from '../../../utils/icon/Face.svg'
import { useDispatch, useSelector } from 'react-redux'

const EndTest = () => {
	const dispatch = useDispatch()
	localStorage.clear()
	const error = useSelector((state: any) => state.error.error)

	return (
		<div className={style.end_menu}>
			<div className={style.endPage}>
				{error === '418' ? (
					<h2>
						Время вышло <Face />
					</h2>
				) : null}

				<h2>Спасибо за прохождение теста!</h2>
				<h4>Ваши результаты сохранены</h4>
				<PeopleIcon />
			</div>
		</div>
	)
}

export default EndTest
