import Auth from '../component/auth/Auth'
import Main from '../pages/Main/Main'
import NotFound from '../pages/NotFound/NotFound'
import StartPage from '../pages/Main/start/StartPage'
import TestPage from '../pages/Main/test/TestPage'
import EndPage from '../pages/Main/end/EndPage'
import RootPage from '../pages/Main/Root/RootPage'
export interface IRoute {
	path: string
	component: React.ComponentType
	exact?: boolean
}

export enum RouteList {
	LOGIN = '/login',
	TEST = '/test'
}

export const publicRoutes = [
	{ path: '/login/:id', exact: true, component: Auth },
	{ path: '/', exact: true, component: RootPage },
	{ path: '*', exact: true, component: NotFound }
]

export const privetRoutes = [
	{
		path: '/test',
		exact: true,
		component: Main,
		children: [
			{ path: 'start', exact: true, component: StartPage },
			{ path: 'Test', exact: true, component: TestPage },
			{ path: 'end', exact: true, component: EndPage }
		]
	},
	{ path: '*', exact: true, component: Main }
]
