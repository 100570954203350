import React, { useEffect, useState } from 'react'
import { Button, Input } from 'antd'
import img from '../../../utils/IqIcon/3/39/3.jpg'
interface IButtonProps {
	buttonData: string[]
	onClick: (answerIndex: string) => void
	allData: any
}

const ButtonInputAnswer = ({ buttonData, onClick, allData }: IButtonProps) => {
	// const img = '../../../utils/IqIcon/3/39/3.jpg'
	const imgPath = '3/39/3.jpg'
	console.log(allData)
	const initialAnswer = allData.peopleAnswer ? allData.peopleAnswer[0] : ''

	useEffect(() => {
		setAnswer(initialAnswer)
	}, [allData])
	const [answer, setAnswer] = useState('')
	return (
		<>
			<div className={'content-white'}>
				<Input
					placeholder='Напишите свой ответ'
					className='h4-regular'
					defaultValue={answer}
					value={answer}
					onChange={(e) => setAnswer(e.target.value)}
				></Input>
			</div>
			<Button type={'default'} onClick={(answerI) => onClick(answer)}>
				<span className='text-center'>Сохранить и продолжить</span>
			</Button>
		</>
	)
}

export default ButtonInputAnswer
