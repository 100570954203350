import React from 'react'

import { Route, Routes } from 'react-router-dom'

import './scss/app.scss'
import { useSelector } from 'react-redux'

import { privetRoutes, publicRoutes } from './router'
import { ErrorWrapper } from './utils/api.interceptor'

const App = () => {
	const { isLoggedInTest } = useSelector((state: any) => state.auth)
	const userInfo = localStorage.getItem('Client')

	let routes: any = isLoggedInTest ? privetRoutes : publicRoutes

	return (
		<>
			<ErrorWrapper>
				<Routes>
					{routes.map((route: any) => (
						<Route
							key={route.path}
							path={route.path}
							element={<route.component />}
						>
							{route.children
								? route.children.map((child: any) => (
										<Route
											key={child.path}
											path={child.path}
											element={<child.component />}
										/>
								  ))
								: null}
						</Route>
					))}
				</Routes>
			</ErrorWrapper>
		</>
	)
}

export default App
