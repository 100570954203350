import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import { ConfigProvider } from 'antd'
import moment from 'moment'

import 'moment/locale/ru'
import ru_RU from 'antd/lib/locale/ru_RU'



moment.locale('ru')
const queryClients = new QueryClient()
const rootElement = document.getElementById('root')

if (rootElement) {
	const root = ReactDOM.createRoot(rootElement)
	root.render(
		<BrowserRouter>
			<QueryClientProvider client={queryClients}>
				<ConfigProvider locale={ru_RU}>
					<Provider store={store}>
						<App />
					</Provider>
				</ConfigProvider>
			</QueryClientProvider>
		</BrowserRouter>
	)
}

//
// <ConfigProvider locale={ru_RU}>
// 	<Provider store={store}>
// 		<App />
// 	</Provider>
// </ConfigProvider>
