import { CURRENT_ID_KEY } from '../utils/Constant/url.constant'

export const getCurrentQId = (): number | null => {
	const id = localStorage.getItem(CURRENT_ID_KEY)
	return id == null ? null : parseInt(id)
}

export const setCurrentQId = (id: number) => {
	localStorage.setItem(CURRENT_ID_KEY, `${id}`)
}
