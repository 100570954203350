import { Spin } from 'antd'
import React, { FC, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'

const Loading: FC = () => {

	const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />
	return (
		<div>
			<Spin indicator={antIcon} />
		</div>
	)
}

export default Loading
