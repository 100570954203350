import React, { FC, useEffect, useState } from 'react'
import SimpleBar from 'simplebar-react'
import { ReactComponent as IconCheck } from '../../../utils/icon/IconCheck.svg'

export interface IcountList {
	id: number
	num: number
	answered: boolean
}
export interface ICountListProps {
	count: IcountList[]
	setCountList: (questId: number) => void

	selectNum: number
	// setCount(index: number): void
}
const CountList: FC<ICountListProps> = ({ count, setCountList, selectNum }) => {
	const data = count ? count : [...Array(count)]
	console.log(data)
	data.sort((a, b) => a.num - b.num)

	//
	// console.log(data)
	// console.log(data)

	return (
		<>
			<div className='header-count-Question'>
				<SimpleBar className='scroll'>
					{data.map((itm: IcountList, index: number) => (
						<div
							className={`list-item ${
								itm.answered ? 'list-item-answered' : ''
							}  ${
								itm.num === selectNum ? 'list-item-active' : ''
							}`}
							onClick={(questId) => setCountList(itm.num)}
							key={index}
						>
							{itm.num} {itm.answered ? <IconCheck /> : ''}
						</div>
					))}
				</SimpleBar>
			</div>

			{/*<div onClick={(questId) => setCountList(3)}>{count}</div>*/}
		</>
	)
}

export default CountList
