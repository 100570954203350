import React, { FC, useEffect, useState } from 'react'
import { Modal as ModalWindow, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { setModalStatus } from '../../redux/Modal/ModalSlice'

import { ReactComponent as IconExclamation } from '../../utils/icon/IconExclamation.svg'
import { checkQuestionTest, endTest } from '../../redux/test/testSlice'
import { AppDispatch } from '../../redux/store'
import { useNavigate } from 'react-router-dom'
import { getCurrentQId } from '../../service/Task.service'
import Button from '../ui/button/Button'
interface IModal {
	refreshOne: (itm: any) => void
	refreshTwo: (itm: any) => void
}

const Modal: FC<IModal> = ({ refreshOne, refreshTwo }) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [allAnswersQ, setAllAnswersQ] = useState(false)
	const { showModal } = useSelector((state: any) => state.ModalTest)
	const dispatch = useDispatch<AppDispatch>()
	const navigate = useNavigate()

	useEffect(() => {
		setAllAnswersQ(false)
		dispatch(checkQuestionTest())
			.unwrap()
			.then((data) => {
				if (data.allAnswers) {
					setAllAnswersQ(true)
				}
			})
			.catch((err) => {})
	}, [showModal === true])

	const showModalClick = () => {
		dispatch(setModalStatus(true))
		// setIsModalOpen(true)
	}

	const handleOk = () => {
		dispatch(setModalStatus(false))
		// setIsModalOpen(false)
	}

	const handleCancel = () => {
		dispatch(setModalStatus(false))
		// setIsModalOpen(false)
	}

	const checkModal = () => {
		dispatch(checkQuestionTest())
			.unwrap()
			.then((data) => {
				refreshOne(getCurrentQId()! + 1)
				refreshTwo(getCurrentQId()! + 1)
				handleCancel()
			})
			.catch((err: any) => {})
	}
	const endTestModal = () => {
		dispatch(endTest())
			.unwrap()
			.then((data) => {
				handleCancel()
				navigate('/test/end')
			})
			.catch((err: any) => {
				handleCancel()
				navigate('/test/end')
			})
	}
	return (
		<>
			<ModalWindow
				className='modal-window'
				open={showModal}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}
			>
				<div className='modal-title'>
					<IconExclamation />
					<p className='title-text'>
						{allAnswersQ
							? 'Вы ответили на все вопросы.Уверены что хотите закончить тест?'
							: 'Вы ответили не на все вопросы.Уверены что хотите закончить тест?'}
					</p>
				</div>
				<div className='modal-btn-wrapper'>
					{allAnswersQ ? null : (
						<Button onClick={() => checkModal()} type={'default'}>
							Вернуться
						</Button>
					)}

					<Button onClick={() => endTestModal()} type={'stroke'}>
						Закончить
					</Button>
				</div>
			</ModalWindow>
		</>
	)
}

export default Modal
