import {
	Checkbox,
	Form,
	Input,
	DatePicker,
	Radio,
	notification,
	Button
} from 'antd'
import 'antd/dist/antd.css'

import style from './Auth.module.scss'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { logIn, loginAuthHeader, loginInfo } from '../../redux/Auth/AuthSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { DateParse } from '../../service/Date.helper'
// import Button from '../ui/button/Button'
import { CloseCircleOutlined } from '@ant-design/icons'

import moment from 'moment'
import { AppDispatch } from '../../redux/store'
import { useTypedSelector } from '../../customHooks/useTypedSelector'
import { useUser } from '../../customHooks/useUser'
import { validEmail, validInitialsRu } from '../../utils/validationFields'
import { AxiosResponse } from 'axios'

interface IFieldsRegexp {
	value?: RegExp
	error?: string
}
interface IFields {
	label: string
	name: string
	rule: string
	regexp: IFieldsRegexp
}
const errorInitial =
	'Запрещены случайный набор символов, цифры и спецсимволы (!“#$%‘()*)'
const initialsUser: IFields[] = [
	{
		label: 'Фамилия',
		name: 'lastName',
		rule: 'заполните поле',
		regexp: { value: validInitialsRu, error: errorInitial }
	},
	{
		label: 'Имя',
		name: 'name',
		rule: 'заполните поле',
		regexp: { value: validInitialsRu, error: errorInitial }
	},
	{
		label: 'Отчество',
		name: 'middleName',
		rule: 'заполните поле',
		regexp: { value: validInitialsRu, error: errorInitial }
	}
]

const checkFields: (props: IFieldsRegexp) => any = ({
	value: valueReg,
	error = 'ошибка проверки поля'
}) => {
	if (valueReg === undefined) {
		return null
	}
	return {
		validator: (_: any, value: any) => {
			if (valueReg.test(value)) {
				return Promise.resolve()
			}
			return Promise.reject(error)
		}
	}
}

const FormPage = () => {
	const [value, setValue] = React.useState('M')
	const [showError, setShowError] = useState(false)
	const [loading, setLoading] = useState(false)
	const { isLoggedInTest } = useUser()
	const { id } = useParams()
	const dispatch = useDispatch<AppDispatch>()
	const [api, contextHolder] = notification.useNotification()
	const onChange = (e: any) => {
		setValue(e.target.value)
	}
	useEffect(() => {
		if (id !== undefined)
			dispatch(loginAuthHeader(id))
				.unwrap()
				.then((data) => {
					if (data.status == 200) {
						dispatch(loginInfo())
							.unwrap()
							.then((data: any) => {
								if (data.data.peopleInTry) {
									localStorage.setItem(
										'ClientTesting',
										JSON.stringify(data.data)
									)
									window.location.reload()
								}
							})
					} else {
						// openNotification(data.response.status)
					}
				})
	}, [id])
	const openNotification = (textError: string) => {
		api.open({
			message: `Ошибка авторизации код ${textError}`,
			description: 'Произошла ошибка авторизации',
			icon: <CloseCircleOutlined style={{ color: 'red' }} />
		})
	}

	const onFinish = (values: any) => {
		if (values.remember == false) {
			setShowError(true)
		} else {
			values.date = DateParse(values.date._d)
			values.id = id
			setLoading(true)
			dispatch(logIn(values))
				.unwrap()
				.then((status: any) => {
					localStorage.setItem('ClientTesting', JSON.stringify(status.data))
				})
				.catch((status) => {
					console.log(status)
					// openNotification(status.status)
					setLoading(false)
				})
		}
	}
	const onFinishFailed = (errorInfo: any) => {}

	return (
		<Form
			name='normal_login'
			layout={'vertical'}
			initialValues={{ remember: false }}
			className={style.FormPage}
			onFinish={onFinish}
		>
			{contextHolder}

			<h1 className='h1-regular text-center'>Вход</h1>
			{initialsUser.map((item) => (
				<Form.Item
					key={item.name}
					className={style.FormItem}
					name={item.name}
					rules={[{ required: true, message: false }, checkFields(item.regexp)]}
				>
					<Input className={style.Input} placeholder={item.label} />
				</Form.Item>
			))}

			<Form.Item
				className={style.FormItem}
				name='date'
				rules={[{ required: true, message: 'Вы не указали Дату рождения' }]}
			>
				<DatePicker
					placeholder='Выберите дату рождения'
					className={style.Input}
					disabledDate={(current) =>
						moment().diff(moment(current), 'month') < 192
					}
				/>
			</Form.Item>

			<Form.Item
				className={style.FormItem}
				name='sex'
				rules={[{ required: true, message: 'Вы не указали пол' }]}
			>
				<Radio.Group
					className={style.RadioGroup}
					onChange={onChange}
					value={value}
					size='large'
				>
					<Radio className='radio-btn' value={'M'}>
						Мужчина
					</Radio>
					<Radio className='radio-btn' value={'W'}>
						Женщина
					</Radio>
				</Radio.Group>
			</Form.Item>

			<Form.Item className={style.FormItemB}>
				<Button htmlType={'submit'} className='btn-primary' type={'primary'}>
					Войти
				</Button>
			</Form.Item>

			<Form.Item
				className={style.FormItem}
				name='remember'
				valuePropName='checked'
				rules={[{ required: false, message: 'Вы не согласились' }]}
			>
				<Checkbox className='checkbox-btn'>
					Согласие на обработку <br />{' '}
					<a
						href={require('../../utils/polit.docx')}
						download='polit.docx'
						target='_blank'
						className='body-regular'
					>
						персональных данных
					</a>
				</Checkbox>
			</Form.Item>
			{showError ? (
				<p style={{ color: 'red' }}>Для продолжения поставьте галочку</p>
			) : null}
		</Form>
	)
}

export default FormPage
