import axios, { AxiosResponse, AxiosError } from 'axios'
import { clearStore, saveToStorage } from './Auth.helper'
import { IUser } from '../../types/user'
import { API_URLT } from '../../utils/Constant/url.constant'
import { AuthHeader } from '../AuthHeader'
import { ErrorStatus } from '../Error/Error.helper'
import { instance } from '../../utils/api.interceptor'

const Login = (user: IUser) => {
	return instance
		.post(
			`${API_URLT}testing/`,
			{
				firstName: user.name,
				middleName: user.middleName,
				lastName: user.lastName,
				birthDate: user.date,
				sex: user.sex
			},
			{ headers: AuthHeader() }
		)
		.then((response) => {
			// if (response.status == 201 || response.status == 204) {
			// 	return response.data
			// }
			return response
		})
		.catch((error: AxiosError<{ additionalInfo: string }>) => {})
}

const loginInfo = () => {
	return axios
		.get(`${API_URLT}testing/`, { headers: AuthHeader() })
		.then((response) => {
			if (response.data) {
			}

			return response
		})
}

const loginAuthHeader = (url: string) => {
	return instance
		.get(`${API_URLT}auth/byurl/${url}`, {})
		.then((response) => {
			if (response.data) {
				localStorage.setItem(
					'srcToken',
					JSON.stringify(response.data.authorization)
				)
			}

			return response
		})
		.catch((err) => {
			return err
		})
}

const logout = () => {
	localStorage.removeItem('Client')
	return axios.post(API_URLT + 'signout').then((response) => {
		return response.data
	})
}

const AuthService = {
	Login,
	logout,
	loginInfo,
	loginAuthHeader
}

export default AuthService
