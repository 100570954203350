import { Button, Result } from 'antd'
import React from 'react'
import style from './NotFound.module.scss'
import { Link } from 'react-router-dom'
import iconNot from '../../utils/icon/Notfound.svg'

import { ArrowLeftOutlined } from '@ant-design/icons'

const NotFound = () => {
	return (
		<>
			<div className='not-found'>
				<div className='info'>
					<h2 className='info-not-found'>
						К сожалению, страница не найдена
					</h2>
					{/*<button className='btn '>Вернуться на главную</button>*/}
				</div>
				<div className='img'>
					<img src={iconNot} alt='' />
				</div>
			</div>
		</>
	)
}

export default NotFound
