import React, { useEffect, useState } from 'react'
import { Button, Col, Input, Row } from 'antd'
import img from '../../../utils/IqIcon/3/39/3.jpg'
interface IButtonProps {
	buttonData: string[]
	onClick: (answerIndex: string) => void
	allData: any
}

const ButtonTenAnswer = ({ buttonData, onClick, allData }: IButtonProps) => {
	// const img = '../../../utils/IqIcon/3/39/3.jpg'
	const imgPath = '3/39/3.jpg'
	console.log(allData)
	const initialAnswer = allData.peopleAnswer ? allData.peopleAnswer[0] : ''

	useEffect(() => {
		setAnswer(initialAnswer)
	}, [allData])

	const [answer, setAnswer] = useState('')
	const arr = [...Array(10)]
	return (
		<>
			<Row justify={'space-between'}>
				{arr.map((item, index) => (
					<Col span={2}>
						<Button
							type={'default'}
							onClick={(answerI) => onClick(`${index + 1}`)}
						>
							<span className='text-center'>{index + 1}</span>
						</Button>
					</Col>
				))}
			</Row>
		</>
	)
}

export default ButtonTenAnswer
