export const API_URLT = '/api/'

export const USER_TRY = '/testing'
export const TEST_URL = [
	{ id: 1, name: 'oca' },
	{ id: 2, name: 'iq' },
	{ id: 3, name: 'test3' },
	{ id: 4, name: 'test4' },
	{ id: 5, name: 'test5' }
]
export const GETTEST = '/api/testing/info'

export const CURRENT_ID_KEY="CurrentQId"

export const TIME_TEST='Time-test'
