import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import style from './start.module.scss'
import { ReactComponent as TimeSvg } from '../../../utils/icon/Time.svg'
import { ReactComponent as CheckSvg } from '../../../utils/icon/Cheack.svg'

import { getQuestion, getTest, startTest } from '../../../redux/test/testSlice'
import { getCurrentQId, setCurrentQId } from '../../../service/Task.service'
import { AppDispatch } from '../../../redux/store'
import { Image as ImageAnd, notification } from 'antd'
import { IInfoTest } from '../../../types/IInfoTest'
import loading from '../../../component/loading/Loading'
import Button from '../../../component/ui/button/Button'
import { loginInfo } from '../../../redux/Auth/AuthSlice'

const StartPage: FC = () => {
	// const testInfo = useSelector((state: any) => state.test.test)
	// const id = testInfo.id
	// let quest = useSelector((state: any) => state.test.question)
	let quest = localStorage.getItem('CurrentQId')
	const dispatch = useDispatch<AppDispatch>()
	const navigate = useNavigate()

	const [Loading, setLoading] = useState(true)
	const [testInfo, setTestInfo] = useState<IInfoTest>()
	const [startedTest, setstartedTest] = useState(false)
	const textButton = startedTest ? 'Продолжить тест' : 'Начать тест'

	useEffect(() => {
		dispatch(getTest())
			.unwrap()
			.then((data) => {
				setTestInfo(data)
				setLoading(false)
			})
			.catch(() => {})

		dispatch(loginInfo())
			.unwrap()
			.then((data) => {
				console.log()
				if (data?.data?.started) {
					setstartedTest(true)
				}
			})
			.catch(() => {})
	}, [])

	const startTesting = () => {
		dispatch(startTest())
			.unwrap()
			.then((data) => {
				if (!getCurrentQId()) {
					setCurrentQId(1)
				}
				navigate('/test/Test')
			})
			.catch((err: any) => {
				navigate('/test/end')
			})
	}

	const QuestionParse = testInfo?.description.match(/^(.*)<img>(.+)$/)
	let Questin
	let Img
	if (QuestionParse) {
		Questin = QuestionParse[1]
		Img = QuestionParse[2]
	} else {
		Questin = 'testInfo?.description'
		Img = ''
	}
	return (
		<>
			{Loading ? null : (
				<div className={style.start_menu}>
					<h2 className='h2-semi-bold'>{testInfo?.name}</h2>

					<p className='text-description body-regular'>
						{testInfo?.description}
					</p>
					{testInfo?.id === 2 || testInfo?.id === 3 ? (
						<>
							<img
								src={require(`../../../utils/IqIcon/example.jpg`)}
								// width={200}
							/>
							<p className='text-description'>
								Правильным ответом будет “2” (железо), так что
								вы нажимаете на ответ “2
							</p>
						</>
					) : null}
					<div >
						{testInfo?.hideElements ? <div className={style.iconWrapperNull}></div>: (
							<div className={style.iconWrapper}>
								<p className='h5-regular text-character'>
									<CheckSvg />
									{testInfo?.numberOfQuestions} вопросов
								</p>
								{testInfo?.itIsHaveTimer ? (
									<p className='h5-regular text-character'>
										<TimeSvg />
										{testInfo?.timeInMinutes} минут
									</p>
								) : null}
							</div>
						)}
					</div>

					<Button
						className='h5-medium'
						onClick={startTesting}
						type={'default'}
					>
						{textButton}
					</Button>
				</div>
			)}
		</>
	)
}

export default StartPage
