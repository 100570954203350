import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

const initialState: any = {
	error: null
}

const errorSlice = createSlice({
	name: 'error',
	initialState,
	reducers: {
		setError: (state, action: any) => {
			state.error = action.payload
		}
	}
})

export const { setError } = errorSlice.actions
export default errorSlice.reducer
