import React, { FC, HTMLAttributes } from 'react'
declare const ButtonShapes: [
	'default',
	'stroke',
	'icon',
	'circle',
	'decorative'
]
export declare type ButtonShape = typeof ButtonShapes[number]

interface IButtonProps extends HTMLAttributes<HTMLButtonElement> {
	children?: React.ReactNode
	className?: string
	icon?: React.ReactNode
	type?: ButtonShape
}
const Button: FC<IButtonProps> = ({ children, className, type, ...props }) => {
	return (
		<button className={className + ` btn-${type}`} {...props}>
			{children}
		</button>
	)
}

export default Button
