import React, { useCallback, useEffect, useMemo, useState } from 'react'
import style from './test.module.scss'
import { notification, Popover } from 'antd'
import { useAnimationControls } from 'framer-motion'
import {
	addAnswer,
	backQuestionTest,
	checkQuestionTest,
	endTest,
	getQuestionList,
	getQuestionTest,
	getTest,
	nextQuestionTest,
	setQuestionTest
} from '../../../redux/test/testSlice'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ButtonAnswer from '../../../component/ui/button/ButtonAnswer'

import { ReactComponent as TimeSvg } from '../../../utils/icon/Time.svg'
import { ReactComponent as CheckSvg } from '../../../utils/icon/Cheack.svg'
import { ReactComponent as IconDown } from '../../../utils/icon/IconDown.svg'

import { TEST_URL, TIME_TEST } from '../../../utils/Constant/url.constant'
import { getCurrentQId, setCurrentQId } from '../../../service/Task.service'
import { IAddAnswer } from '../../../types/IAddAnswer'
import { AppDispatch } from '../../../redux/store'
import { IInfoTest } from '../../../types/IInfoTest'
import { setError } from '../../../redux/user/errorSlice'
import CountList from './CountList'
import Loading from '../../../component/loading/Loading'
import { setModalStatus } from '../../../redux/Modal/ModalSlice'
import Modal from '../../../component/Modal/Modal'
import ButtonInputAnswer from '../../../component/ui/button/ButtonInputAnswer'
import ButtonTenAnswer from '../../../component/ui/button/ButtonTenAnswer'
const TestPage = () => {
	const [loading, setLoading] = useState(true)
	const [value, setValue] = useState({
		answers: [],
		quest: '',
		num: 1,
		questType: 1
	})
	const [count, setCount] = useState<number>()
	const [questList, setQuestList] = useState()

	const [time, setTime] = useState<number>(() => {
		const timeString = localStorage.getItem(TIME_TEST)
		return timeString ? parseInt(timeString) : 0
	})

	const dispatch = useDispatch<AppDispatch>()
	const navigate = useNavigate()
	const [testInfo, setTestInfo] = useState<IInfoTest>()
	const [api, contextHolder] = notification.useNotification()

	const setTimeState = useCallback(
		(time: number) => {
			console.log(time)
			if (testInfo!.timeInMinutes - time < 0) {
				navigate('/test/end')
			}
			setTime(time)
			localStorage.setItem(TIME_TEST, time.toString())
		},
		[testInfo]
	)

	useEffect(() => {
		dispatch(getTest())
			.unwrap()
			.then((data) => {
				console.log(2)
				console.log(data.status)
				setTestInfo(data)
				setLoading(false)
			})
			.catch(() => {
				console.log(1)
				navigate('/test/end')
			})
	}, [])

	useEffect(() => {
		console.log(time)
		const int = setInterval(() => {
			setTimeState(time + 1)
			console.log(time)
		}, 60000)
		return () => {
			clearInterval(int)
		}
	}, [setTimeState, time])

	useEffect(() => {
		dispatch(getQuestionTest())
			.unwrap()
			.then((data) => {
				setValue(data!.testQuestion)
				if (data!.testQuestion === 204 || data!.testQuestion === 418) {
					dispatch(setError(data!.testQuestion))
					// dispatch(endTest())
					// 	.unwrap()
					// 	.then((data) => {
					// 		navigate('/test/end')
					// 	})
					// 	.catch((err) => {})
				}
			})
			.catch((err: any) => {
				navigate('/test/end')
			})
		dispatch(getQuestionList())
			.unwrap()
			.then((data) => {
				setQuestList(data)
				// animate()
			})
			.catch((err: any) => {})
	}, [count])

	const addAnswerButton = (rezult: string) => {
		const answerData: IAddAnswer = {
			questionId: getCurrentQId()!,
			answerIndex: rezult
		}

		dispatch(addAnswer(answerData))
			.unwrap()
			.then(() => {
				setCurrentQId(getCurrentQId()! + 1)
				setCount(getCurrentQId()! + 1)
				if (testInfo?.itIsDirect) {
				} else {
					// if (getCurrentQId()! + 1 > testInfo?.numberOfQuestions!) {
					// } else {
					// 	setCurrentQId(getCurrentQId()! + 1)
					// 	setCount(getCurrentQId()!)
					// }
				}
			})
			.catch((err) => console.log(err))
	}

	const nextQuestion = () => {
		dispatch(nextQuestionTest())
			.unwrap()
			.then((data) => {
				setCurrentQId(getCurrentQId()! + 1)
				setCount(getCurrentQId()! + 1)
			})
			.catch((err: any) => {})

		// const QuestID = getCurrentQId()
		// if (QuestID !== testInfo!.numberOfQuestions) {
		// 	setCurrentQId(QuestID! + 1)
		// 	setCount(QuestID! + 1)
		// }
	}
	const EarlyQuestion = () => {
		dispatch(backQuestionTest())
			.unwrap()
			.then((data) => {
				setCurrentQId(getCurrentQId()! + 1)
				setCount(getCurrentQId()! + 1)
			})
			.catch((err: any) => {})
	}
	const setQuest = (id: number) => {
		dispatch(setQuestionTest(id))
			.unwrap()
			.then((data) => {
				setCurrentQId(getCurrentQId()! + 1)
				setCount(getCurrentQId()! + 1)
			})
			.catch((err: any) => {})

		//
		// setCurrentQId(id)
		// setCount(id)
	}
	const QuestionParse = value.quest?.match(/^(.*)<img>(.+)$/)
	let Questin
	let Img
	if (QuestionParse) {
		Questin = QuestionParse[1]
		Img = QuestionParse[2]
	} else {
		Questin = value.quest
		Img = ''
	}

	const TestEnd = () => {
		if (testInfo?.itIsDirect) {
			dispatch(checkQuestionTest())
				.unwrap()
				.then((data) => {
					if (data.allAnswers) {
						dispatch(endTest())
							.unwrap()
							.then((data) => {
								navigate('/test/end')
							})
							.catch((err: any) => {
								navigate('/test/end')
							})
					} else {
						api.open({
							message: 'Уведомление',
							description: 'Вы не ответили на  все вопросы',

							icon: <ExclamationCircleOutlined style={{ color: '#1890ff' }} />
						})
						setCurrentQId(getCurrentQId()! + 1)
						setCount((itm) => itm! + 1)
					}
				})
				.catch((err: any) => {})
		} else {
			dispatch(setModalStatus(true))

			// dispatch(endTest())
			// 	.unwrap()
			// 	.then((data) => {
			// 		navigate('/test/end')
			// 	})
			// 	.catch((err: any) => {
			// 		navigate('/test/end')
			// 	})
		}
	}

	const [shake, setShake] = useState(false)

	const animate = () => {
		setShake(true)
		setTimeout(() => setShake(false), 1000)
	}

	return (
		<>
			{loading ? (
				<Loading />
			) : (
				<>
					<Modal
						refreshOne={(itm: any) => setCurrentQId(itm)}
						refreshTwo={(itm) => setCount(itm)}
					/>
					{contextHolder}
					<div className='header-test'>
						<div className='header-wrapper'>
							<div className='test-header-title'>
								<span>{testInfo!.name}</span>
							</div>
							{testInfo!.hideElements ? (
								<>
									<div className='test-header-title'>
										<a onClick={() => TestEnd()}>Завершить тест</a>
									</div>
								</>
							) : (
								<div className='info-time'>
									{testInfo!.itIsHaveTimer ? (
										<div
											className={
												testInfo!.timeInMinutes - time < 3 ? 'time-danger' : ''
											}
										>
											<TimeSvg />
											<span>
												Осталось: {testInfo!.timeInMinutes - time} мин
											</span>
										</div>
									) : null}
									<div>
										<CheckSvg />
										<div style={{ position: 'relative' }}>
											<Popover
												// className={style.popover}
												placement='bottom'
												content={
													<CountList
														count={questList!}
														selectNum={value.num}
														setCountList={(questId) => setQuest(questId)}
													/>
												}
												trigger='click'
											>
												<span className='hover-quest'>
													Вопрос: {value.num}/{testInfo!.numberOfQuestions}
													<IconDown className='icon-list' />
												</span>
											</Popover>
										</div>
									</div>
									<div className='test-header-title'>
										<a onClick={() => TestEnd()}>Завершить тест</a>
									</div>
								</div>
							)}
						</div>
					</div>
					<div className={style.test}>
						<div
							className={`${style.test_content} ${
								shake ? `animateTrans` : null
							}`}
						>
							<div
								className={
									testInfo!.itIsDirect
										? style.headerContentDirect
										: style.headerContent
								}
							>
								<div onClick={() => EarlyQuestion()} className={style.arrowBtn}>
									<span>{'<'} </span> Назад
								</div>

								<div>
									{value.num}/{testInfo!.numberOfQuestions}
								</div>
								<div onClick={() => nextQuestion()} className={style.arrowBtn}>
									Вперед <span> {'>'}</span>
								</div>
							</div>
							<div className={style.question}>
								<>
									{Questin ? <p>{Questin}</p> : null}
									{Img ? (
										<img
											src={require(`../../../utils/IqIcon/${Img}`)}
											alt='1'
										/>
									) : null}
								</>
							</div>
							{value?.questType === 1 ? (
								<div className={'btn_wrapper-inline'}>
									{value.answers ? (
										<ButtonAnswer
											onClick={(answer: string) => addAnswerButton(answer)}
											buttonData={value.answers}
											allData={value}
										></ButtonAnswer>
									) : null}
								</div>
							) : null}
							{value?.questType === 2 ? (
								<ButtonInputAnswer
									onClick={(answer: string) => addAnswerButton(answer)}
									buttonData={value.answers}
									allData={value}
								/>
							) : null}
							{value?.questType === 3 ? (
								<ButtonTenAnswer
									onClick={(answer: string) => addAnswerButton(answer)}
									buttonData={value.answers}
									allData={value}
								/>
							) : null}
							{value?.questType === 4 ? (
								<div className={'btn_wrapper'}>
									{value.answers ? (
										<ButtonAnswer
											onClick={(answer: string) => addAnswerButton(answer)}
											buttonData={value.answers}
											allData={value}
										></ButtonAnswer>
									) : null}
								</div>
							) : null}
						</div>
					</div>
				</>
			)}
		</>
	)
}

export default TestPage
