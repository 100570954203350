import React from 'react'
import 'antd/dist/antd.css'
import FormPage from './Form'
import style from './Auth.module.scss'
// <div className={style.Auth}>

const App = () => {
	return (
		<div className={style.AuthWrapper}>
			<FormPage />
		</div>
	)
}
export default App
